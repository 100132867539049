/* @import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Fira Sans', 'Hanken Grotesk', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code { */
/*   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
/*     monospace; */
/* } */

